import { FunctionComponent, useState, useEffect } from 'react';
// @ts-ignore
import { signIn, useSession } from 'next-auth/client';
import { useRouter } from 'next/router';
import { OktaAuth } from '@okta/okta-auth-js';
import { Layout } from 'components/Layout/Layout';
import Head from 'next/head';
import { Consts } from 'styles/consts';
import { ColorDefs } from 'styles/colorDefs';
import styled from 'styled-components';
import { OKTA_DOMAIN, CLIENT_ID } from 'consts';
import { scrollTop } from 'utils';
import { Footer } from 'components/Layout/Footer';
import { FlexWrapper, FlexBox } from 'components/Layout/Flex';
import { ActionButton } from '../components/Buttons/ActionButton';
import { Spacer } from '../components/Layout/Spacer';
import { Header } from '../components/Layout/Header';
import * as Sentry from '@sentry/react';

const Login: FunctionComponent = () => {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [session] = useSession();
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [loginErrorOkta, setLoginErrorOkta] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  const getAuthClient = () =>
    new OktaAuth({
      issuer: `${OKTA_DOMAIN}/oauth2/default`,
      clientId: CLIENT_ID,
      tokenManager: {
        storage: 'localStorage',
      },

      redirectUri: 'http://localhost:3000/login',
    });
  useEffect(() => {
    if (router.query.error) {
      setLoginError(true);
      setLoading(false);
    }
  }, [router]);

  useEffect(() => {
    if (email.length > 0 && password.length > 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [email, password]);

  useEffect(() => {
    if (session?.expires) {
      router.push('/account');
    }
  }, [session]);

  const handleLogin = async () => {
    try {
      const authClient = getAuthClient();
      setLoading(true);
      const {
        sessionToken,
        user: { id: userId },
      } = await authClient.signInWithCredentials({
        username: email,
        password,
      });

      await signIn('credentials', {
        userId,
        sessionToken,
      });

      await authClient.session.setCookieAndRedirect(
        sessionToken,
        `${window.location.origin}/account`,
      );
    } catch (error) {
      if (error.errorCode === 'E0000004') {
        setLoginErrorOkta(true);
      }
      setLoading(false);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Head>
        <title>The Earth Prize - Login</title>
        <meta property="og:title" content="The Earth Prize - Login" />
        <meta property="og:image" content="/images/cover.jpg" />
      </Head>
      <Header />
      <FlexWrapperStyled>
        <FlexBox>
          <H1>
            Welcome
            <br /> To The Earth Prize
          </H1>
          <Spacer height={30} />
          {loginError && (
            <FormError>
              Sorry, your email or password was incorrect. Please double-check
              your credentials.
            </FormError>
          )}
          {loginErrorOkta && (
            <FormError>
              We couldn’t find an account with that username or password.
            </FormError>
          )}
          <FormControl>
            <FormInputWrapper>
              <FormInput>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Address"
                />
              </FormInput>
            </FormInputWrapper>
            <FormInputWrapper>
              <FormInput>
                <Input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  type="password"
                />
              </FormInput>
            </FormInputWrapper>
          </FormControl>
          <Spacer height={20} />
          <FormControl>
            {/*<Registration>*/}
            {/*  Don&apos;t have an account?{' '}*/}
            {/*  <a href="/registration">Register now</a>*/}
            {/*</Registration>*/}
            <ForgotPassword href="/reset-password">
              Forgot password?
            </ForgotPassword>
          </FormControl>
          <Spacer height={20} />
          <SubmitControl>
            <ActionButton
              background="#0AB7BC"
              color="#000000"
              onClick={async () => await handleLogin()}
              disabled={isSubmitDisabled}
            >
              {isLoading ? 'Loading...' : 'Log in'}
            </ActionButton>
          </SubmitControl>
        </FlexBox>
        <FlexBox withoutPadding>
          <Image />
        </FlexBox>
      </FlexWrapperStyled>
      <Footer onTopClick={() => scrollTop()} />
    </Layout>
  );
};

const FlexWrapperStyled = styled(FlexWrapper)`
  padding-top: 80px;
`;

const Image = styled.div`
  max-width: 580px;
  width: 100%;
  height: 100%;
  max-height: 500px;
  position: relative;
  background-image: url('/images/image69.webp');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: ${Consts.mobileBreakPoint}) {
    width: 80%;
    margin: 0 auto;
  }
`;

const FormControl = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: ${Consts.mobileBreakPoint}) {
    grid-template-columns: 1fr;
  }
`;

const Registration = styled.p`
  font-size: 12px;
  color: ${ColorDefs.robinsEggBlue};

  a {
    color: ${ColorDefs.white};
  }
`;

const ForgotPassword = styled.a`
  font-size: 12px;
  color: ${ColorDefs.robinsEggBlue};
`;

const FormInputWrapper = styled.div`
  width: 100%;
`;

const FormInput = styled.div`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid ${ColorDefs.robinsEggBlue};
  display: flex;
`;

const Input = styled.input`
  width: 100%;
  height: 20px;
  background: transparent;
  color: ${ColorDefs.robinsEggBlue};
  border: none;
  font-size: 15px;
  ::placeholder {
    color: ${ColorDefs.robinsEggBlue};
  }
  :-ms-input-placeholder {
    color: ${ColorDefs.robinsEggBlue};
  }
  ::-ms-input-placeholder {
    color: ${ColorDefs.robinsEggBlue};
  }
`;

const SubmitControl = styled.div`
  margin: 5px 5px 0;
  @media (min-width: ${Consts.tabletBreakPoint}) {
    display: flex;
    justify-content: flex-end;
  }
`;

const FormError = styled.span`
  font-size: 12px;
  color: red;
  margin: 10px 0;
`;

const H1 = styled.h1`
  font-size: 26px;
  text-transform: uppercase;
  color: #0ab7bc;
  font-weight: bold;
  @media (min-width: ${Consts.mobileBreakPoint}) {
    font-size: 32px;
  }
  @media (min-width: ${Consts.tabletBreakPoint}) {
    font-size: 50px;
  }
`;

export default Login;
